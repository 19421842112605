"use client";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import { apiURL } from "../../../../utilities/config/server";
import { QuestionAttachmentType, QuestionExpertAttachmentType } from "../../../../utilities/redux/types";
import { dateInFrench } from "../../../../utilities/tools/date";
import { BsFiletypeCsv, BsFiletypeDocx, BsFiletypePdf, BsFiletypeXls, BsFiletypeXlsx } from "../../../react-icons/bs";
import { IoIosAttach } from "../../../react-icons/io";
import { IconButton } from "../../buttons";
import { Shape } from "../../shapes";

interface Props extends HTMLAttributes<HTMLDivElement> {
  attachment: QuestionAttachmentType | QuestionExpertAttachmentType;
  app: "company" | "expert" | "admin";
  senderType?: string;
  deleteFile?: (param: any) => void;
  onClick?: () => void;
  dateDisplay?: boolean;
  aspectRatio?: boolean;
};

export function Preview({ senderType = "", attachment, app, deleteFile, onClick, dateDisplay = false, aspectRatio = false, ...props }: Props) {
  const extAccepted = ["csv", "pdf", "xls", "xlsx", "docx", "jpeg", "jpg", "bmp", "png"];

  return (
    <div
      {...props}
      className={classNames(`preview${(app === "expert" && senderType === "") || (app !== "expert" && senderType === "_from_expert") ? "-container" : ""} `, props.className)}
    >
      {dateDisplay && attachment?.createdAt && <p>{dateInFrench(attachment?.createdAt)}</p>}
      <div className={classNames("img-container", { clickable: onClick })} onClick={onClick} style={{ aspectRatio: aspectRatio && "1" }}>
        {attachment?.originalExtension?.toLowerCase() === "jpeg" ||
          attachment?.originalExtension?.toLowerCase() === "jpg" ||
          attachment?.originalExtension?.toLowerCase() === "bmp" ||
          attachment?.originalExtension?.toLowerCase() === "png" ? (
          <img src={attachment?.preview ?? `${apiURL}${app}/questionAttachment/thumbnail${senderType}/${attachment?.id}&time=${new Date()}`} alt="attachment" />
        ) : (
          <div className="without-image pt-1">
            <Shape form="square" color="infoBackground">
              {!extAccepted.includes(attachment?.originalExtension?.toLowerCase()) && <IoIosAttach />}

              {attachment?.originalExtension?.toLowerCase() === "csv" && <BsFiletypeCsv />}

              {attachment?.originalExtension?.toLowerCase() === "pdf" && <BsFiletypePdf />}

              {attachment?.originalExtension?.toLowerCase() === "xls" && <BsFiletypeXls />}

              {attachment?.originalExtension?.toLowerCase() === "xlsx" && <BsFiletypeXlsx />}

              {attachment?.originalExtension?.toLowerCase() === "docx" && <BsFiletypeDocx />}
            </Shape>
            <p className="name pb-1 ellipsis" style={{ userSelect: "none" }}>
              {attachment?.originalBaseName}
            </p>
          </div>
        )}
      </div>

      {deleteFile && (
        <IconButton
          type="transparent"
          icon="close"
          cb={() => deleteFile(attachment)}
          size={18}
          form="circle"
          tooltip="Supprimer la pièce jointe"
        />
      )}
    </div>
  );
}
