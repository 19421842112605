"use client";

import classNames from "classnames";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { serverURL } from "../../../../utilities/config/server";
import { AccountingFirmType } from "../../../../utilities/redux/types";
import { MdModeEditOutline } from "../../../react-icons/md";
import { AccountingFirmPlaceholder } from "./AccountingFirmPlaceholder";

interface Props extends HTMLAttributes<HTMLDivElement> {
  accountingFirm?: Partial<AccountingFirmType>;
  alt?: string;
  size?: number;
  className?: string;
  type?: "thumbnail" | "cover";
  app: "expert" | "company" | "admin";
  handleModify?: (e: any) => void;
  preview?: string;
};

export function AccountingFirmPicture({
  accountingFirm,
  size = 30,
  className = "",
  type = "thumbnail",
  app,
  preview,
  handleModify,
  children,
  ...props
}: Props) {
  const [notFound, setNotFound] = useState(false);
  const inputRef: any = useRef(null);

  useEffect(() => {
    preview && setNotFound(false);
  }, [preview, accountingFirm?.slug, accountingFirm?.isPictureDefined]);

  return (
    <div
      className={classNames("picture accounting-firm-picture", className, { clickable: handleModify })}
      style={{ width: size + "px", height: size + "px" }}
      {...props}
      onClick={handleModify ? () => inputRef?.current?.click() : () => { }}
    >

      <input
        ref={inputRef}
        hidden
        type="file"
        accept="image/*"
        onChange={handleModify ? (e: any) => handleModify(e?.target?.files) : () => { }}
      />
      {/*/////////////// Clickable ///////////////////////////////////*/}

      <div className="img-container">
        {accountingFirm?.slug && handleModify && (
          <>
            <div className="cache" />
            <MdModeEditOutline className="icon modify" size={40} />
          </>
        )}

        {notFound == false
          && accountingFirm?.slug
          && (accountingFirm?.isPictureDefined == true || preview)
          && <img
            // fill
            src={
              preview
                ? preview
                : `${serverURL}/api/${app}/accounting_firm/${type}/${accountingFirm?.slug}?time=${(accountingFirm?.updatedAt ?? accountingFirm?.createdAt) ? Date.parse(new Date(accountingFirm?.updatedAt ?? accountingFirm?.createdAt)?.toISOString()) : null}`
            }
            aria-hidden
            onError={() => {
              setNotFound(true);
              return;
            }}
          />
        }

        {(!accountingFirm?.isPictureDefined || notFound == true || !accountingFirm?.slug) && !preview && (
          <AccountingFirmPlaceholder />
        )}
      </div>

      {children}
    </div>
  );
}
