"use client";

import classNames from "classnames";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { serverURL } from "../../../../utilities/config/server";
import { CompanyType } from "../../../../utilities/redux/types";
import { MdModeEditOutline } from "../../../react-icons/md";
import { CompanyPlaceholder } from "./CompanyPlaceholder";

interface Props extends HTMLAttributes<HTMLDivElement> {
  company?: Partial<CompanyType>;
  alt?: string;
  size?: number;
  className?: string;
  type?: "thumbnail" | "cover";
  app: "expert" | "company" | "admin";
  handleModify?: (e: any) => void;
  preview?: string;
};

export function CompanyPicture({
  company,
  size = 30,
  className = "",
  type = "thumbnail",
  app,
  preview,
  handleModify,
  children,
  ...props
}: Props) {
  const [notFound, setNotFound] = useState(false);
  const inputRef: any = useRef(null);

  useEffect(() => {
    preview && setNotFound(false);
  }, [preview, company?.isPictureDefined, company?.slug]);

  return (
    <div
      {...props}
      className={classNames("picture company-picture ", className, { clickable: handleModify })}
      style={{ ...props?.style, width: size + "px", height: size + "px" }}
      onClick={handleModify ? () => inputRef?.current?.click() : () => { }}
    >
      <input
        ref={inputRef}
        hidden
        type="file"
        accept="image/*"
        onChange={handleModify ? (e: any) => handleModify(e?.target?.files) : () => { }}
      />

      {/*/////////////// Clickable ///////////////////////////////////*/}

      <div className="img-container">
        {handleModify && (
          <>
            <div className="cache" />
            <MdModeEditOutline className="icon modify" size={40} />
          </>
        )}

        {notFound === false
          && company?.slug
          && (company?.isPictureDefined == true || preview)
          && <img
            // fill
            src={
              preview ? preview : `${serverURL}/api/${app}/company/${type}/${company?.slug}?time=${(company?.updatedAt ?? company?.createdAt) ? Date.parse(new Date(company?.updatedAt ?? company?.createdAt)?.toISOString()) : null}`
            }
            aria-hidden
            onError={() => {
              setNotFound(true);
              return;
            }}
          />
        }

        {(!company?.isPictureDefined || notFound == true || !company?.slug) && !preview && <CompanyPlaceholder />}
      </div>

      {children}
    </div>
  );
}
